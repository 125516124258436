import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchMembers, fetchTeams } from '../api/api';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Checkbox,
} from '@mui/material';

const MembersPage = () => {
  const { user } = useContext(AuthContext);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const getMembers = async () => {
      try {
        const membersData = await fetchMembers();
        setMembers(membersData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    getMembers();
  }, []);

  useEffect(() => {
    const getTeams = async () => {
      const teamsData = await fetchTeams();
      setTeams(teamsData);
    };
    getTeams();
  }, []);

  return (
    <Container>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Members
      </Typography>
      {user && user.role === 'admin' && (
        <Button component={Link} to="/members/new" variant="contained" color="primary" style={{ marginBottom: '20px', float: 'right' }}>
          Add Member
        </Button>
      )}
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Subs Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => (
                <TableRow key={member.id} component={Link} to={`/members/${member.id}`} style={{ textDecoration: 'none' }}>
                  <TableCell>{member.name}</TableCell>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>
                    {teams.find((team) => team.id === member.team_id)?.name || ''}
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={member.subscription_fees_paid} disabled />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default MembersPage;